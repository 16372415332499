import { Router } from "@reach/router";
import * as React from "react";
import loadable from "@loadable/component";
import PageLoading from "../../components/common/pageLoading";

const NovelDetailPage = loadable(
  () =>
    import(
      /* webpackChunkName: "novel-detail" */
      "../../templates/pages/novel/detail"
    ),
  {
    fallback: <PageLoading />,
  }
);
const NovelChapterPage = loadable(
  () =>
    import(
      /* webpackChunkName: "novel-chapter" */
      "../../templates/pages/novel/chapter"
    ),
  {
    fallback: <PageLoading />,
  }
);
const NotFoundPage = loadable(
  () => import(/* webpackChunkName: "404" */ "../404"),
  {
    fallback: <PageLoading />,
  }
);

const Novel: React.FC = () => {
  return (
    <>
      <Router basepath="/novel">
        <NovelDetailPage path="/:title" />
        <NovelChapterPage path="/:title/:chapterNumber" />

        <NotFoundPage default />
      </Router>
    </>
  );
};

export default Novel;
